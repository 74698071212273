import React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/layout';
import Leistung from '../components/leistung'
import { SiteWrapper, SiteContent, FlexBox, mq } from "../components/styles";
import { useQuery } from '@apollo/react-hooks';
import { motion } from 'framer-motion';
import {gql} from 'apollo-boost';
import {css} from '@emotion/core';
import SEO from '../components/seo';

const LEISTUNGEN = gql`
{
  leistungen(orderBy: reihenfolge_ASC){
    id
    titel
    beschreibung
    stage
    teammitglied{
      vorname
      nachname
    },
  },
  kurse{
    id
    titel
    stage
  }
}

`

const Leistungen = () => {
    const { loading, error, data } = useQuery(LEISTUNGEN)
    if (error) return <Layout layoutImage="leistungen"><p>error</p></Layout> 
    else if(loading) return <Layout layoutImage="leistungen"></Layout>
    else if(data) return(
    <Layout layoutImage="leistungen"> 
    <SiteWrapper>
        <SiteContent> 
          <SEO title="Leistungen" />
          <FlexBox id="start"  css={mq({marginBottom: "4em", flexDirection: ["column","column", "row", "row"]})}>
            <div css={mq({width: ["100%", "100%", "50%", "50%"]})}> 
            <h2>Leistungen</h2>
              <ul> 
                {data.leistungen.map((leistung, index) => {return <li key={index}><Link to={"/leistungen#" + leistung.id} >{leistung.titel}</Link></li>})}
              </ul>
            </div>
            <div css={mq({width: ["100%", "100%", "50%", "50%"]})}> 
            <h2>Kurse</h2>
              <ul> 
                {data.kurse.map((kurs, index) => {return <li key={index}><Link to={"/kurse-und-anmeldung#" + kurs.id} >{kurs.titel}</Link></li>})}
              </ul>
            </div>
          </FlexBox>
          <motion.div initial={{opacity:0}} animate={{opacity: 1}}> 
            <Leistung data={data} />
          </motion.div>
        </SiteContent>
    </SiteWrapper>
    </Layout>
    )
}

export default Leistungen