import React from 'react'
import {Link} from 'gatsby';
import {css} from '@emotion/core';
import {Divider, FlexBox, FlexContainerRow, mq} from './styles';

const Leistung = ({data: {leistungen}}) => {
   
    return(
    <div > 
        {leistungen.map((leistungInfo, index ) => (
        
         <div key={index} css={{scrollSnapAlign: "center"}}>  
            {leistungInfo.stage == "PUBLISHED" && 
                <div css={css`margin-bottom: 2em; margin-top: 2em;`}> 
                <FlexContainerRow  css={mq({alignItems: "center"})}> 
                    <FlexBox direction="column" css={mq({order: 2, height: "inherit",  width: ["100%", "100%","34%", "34%"], background: "#FFAD3D",  padding: "1em"})}>
                    {leistungInfo.teammitglied.map((mitglied, index) =>{
                    return  <h6 ><Link to={"/team/" + mitglied.vorname.toLowerCase().replace(/\s+/g, '-') + "-" + mitglied.nachname.toLowerCase()}>{mitglied.vorname + " " + mitglied.nachname}</Link></h6>
    
                    } )}
                    </FlexBox>
                    <FlexBox direction="column" css={mq({order: 1,width: ["100%", "100%","66%", "66%"], paddingRight: "2em"})}>
                        <div id={leistungInfo.id} css={{position: "absolute", marginTop: "-30%" }} ></div>
                        <h4 >{leistungInfo.titel} </h4>
                        <p>{leistungInfo.beschreibung}</p>
                        <h6 css={{margin: "1em 0"}}>
                            <Link to="/leistungen/#start">Seite nach oben</Link>
                        </h6>
                    </FlexBox>
                </FlexContainerRow>
            </div>
        }
        <Divider />
        </div> 
        ))}
    </div>
    )

}


export default Leistung;